export default class ApiService {
    static async ExtraInfo() {
        let data = {}

        return await apiConnect('/extra_info', data)
    }
    static async LoginUser(email, password) {
        let data = {
            'email': email,
            'password': password
        }

        return await apiConnect('/login', data)
    }
    static async AllStats() {
        let data = {}

        return await apiConnect('/dashboard/get-all-stats', data)
    }
    static async AllFinanceStats() {
        let data = {}

        return await apiConnect('/dashboard/get-all-finance-stats', data)
    }
    static async AllUsers(page, section, search) {
        let data = {
            page,
            search, 
            section
        }

        return await apiConnect('/dashboard/get-users', data)
    }
    static async GetUserSimple(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/get-user-simple', data)
    }
    static async GetUser(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/get-user', data)
    }
    static async UpdateUserApi(id, name, username) {
        let data = {
            id, 
            name,
            username
        }

        return await apiConnect('/dashboard/update-user', data)
    }
    static async DeleteUserBankAccount(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/delete-user-account', data)
    }
    static async GetUserTasks(user_id, page, type) {
        let data = {
            user_id,
            page,
            type
        }

        return await apiConnect('/dashboard/get-user-tasks', data)
    }
    static async ClearUserSocialHandle(user_id, type) {
        let data = {
            user_id,
            type
        }

        return await apiConnect('/dashboard/clear-user-social-handle', data)
    }
    static async AllBlogs(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-blogs', data)
    }
    static async GetBlog(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/get-blog', data)
    }
    static async ApproveBlog(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/approve-blog', data)
    }
    static async RejectBlog(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/reject-blog', data)
    }
    static async AllForums(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-forums', data)
    }
    static async GetForum(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/get-forum', data)
    }
    static async ApproveForum(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/approve-forum', data)
    }
    static async RejectForum(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/reject-forum', data)
    }
    static async AllJobs(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-jobs', data)
    }
    static async GetJob(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/get-job', data)
    }
    static async ApproveJob(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/approve-job', data)
    }
    static async RejectJob(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/reject-job', data)
    }
    static async GetWallets(page, search, order_by, gte_field, gte_amount) {
        let data = {
            page,
            search,
            order_by,
            gte_field,
            gte_amount,
        }

        return await apiConnect('/dashboard/get-wallets', data)
    }
    static async GetSubscriptions(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-subscriptions', data)
    }
    static async GetTransactions(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-transactions', data)
    }
    static async GetWithdrawals(page, search, order_by) {
        let data = {
            page,
            search, 
            order_by
        }

        return await apiConnect('/dashboard/get-withdrawals', data)
    }
    static async ManageWithdrawal(data) {

        return await apiConnect('/dashboard/manage-withdrawal', data)
    }
    static async GetSocialWithdrawals(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-social-withdrawals', data)
    }
    static async ManageSocialWithdrawal(data) {

        return await apiConnect('/dashboard/manage-social-withdrawal', data)
    }
    static async GetAdverts(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-adverts', data)
    }
    static async SaveAdvert(data) {

        return await apiConnect('/dashboard/save-advert', data)
    }
    static async ManageAdvert(data) {

        return await apiConnect('/dashboard/manage-advert', data)
    }
    static async GetAdvert(data) {

        return await apiConnect('/dashboard/get-advert', data)
    }
    static async GetUserStats(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/user-stats', data)
    }
    static async GetNotifications(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-notifications', data)
    }
    static async GetPosts(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-posts', data)
    }
    static async GetPost(id) {
        let data = { id }

        return await apiConnect('/dashboard/get-post', data)
    }
    static async ApprovePost(id) {
        let data = { id }

        return await apiConnect('/dashboard/approve-post', data)
    }
    static async ApprovePostPayUser(id) {
        let data = { id }

        return await apiConnect('/dashboard/approve-post-pay-user', data)
    }
    static async DisapprovePost(id) {
        let data = { id }

        return await apiConnect('/dashboard/disapprove-post', data)
    }
    static async UpdatePost(id, topic, post) {
        let data = { id, topic, post }

        return await apiConnect('/dashboard/update-post', data)
    }
    static async SavePostAds(data) {

        return await apiConnect('/dashboard/save-post-ad', data)
    }
    static async SavePostGoogleAds(data) {

        return await apiConnect('/dashboard/save-post-google-ad', data)
    }
    static async GetPostAds(page, search, section) {
        let data = {
            page,
            search,
            section
        }

        return await apiConnect('/dashboard/get-post-ads', data)
    }
    static async PausePostAd(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/pause-post-ad', data)
    }
    static async ApprovePostAd(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/approve-post-ad', data)
    }
    static async ActivatePostAd(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/activate-post-ad', data)
    }
    static async CompletePostAd(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/complete-post-ad', data)
    }
    static async GetSettings(page, search, per_page) {
        let data = {
            page: page,
            search: search,
            per_page
        }

        return await apiConnect('/dashboard/get-settings', data)
    }
    static async SaveSetting(name, value) {
        let data = {
            name,
            value
        }

        return await apiConnect('/dashboard/save-setting', data)
    }
    static async UpdateSetting(id, name, value) {
        let data = {
            id,
            name,
            value
        }

        return await apiConnect('/dashboard/update-setting', data)
    }
    static async GetAccounts(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-accounts', data)
    }
    static async GetProductExtraInfos(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/product-extra-infos', data)
    }
    static async SaveProductBrand(name) {
        let data = {
            name: name
        }

        return await apiConnect('/dashboard/save-product-brand', data)
    }
    static async SaveProductSize(name) {
        let data = {
            name: name
        }

        return await apiConnect('/dashboard/save-product-size', data)
    }
    static async SaveProductColor(name) {
        let data = {
            name: name
        }

        return await apiConnect('/dashboard/save-product-color', data)
    }
    static async SaveProductCategory(name, image) {
        let data = {
            name: name,
            image: image
        }

        return await apiConnect('/dashboard/save-product-category', data)
    }
    static async SaveProductSubCategory(category_id, name) {
        let data = {
            name: name,
            category_id: category_id
        }

        return await apiConnect('/dashboard/save-product-sub-category', data)
    }
    static async GetEvents(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-events', data)
    }
    static async GetProducts(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-products', data)
    }
    static async GetCourses(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-courses', data)
    }
    static async GetShops(page, search) {
        let data = {
            page: page,
            search: search
        }

        return await apiConnect('/dashboard/get-shops', data)
    }
    static async ApproveShop(id) {
        let data = {}

        return await apiConnect('/dashboard/approve-shop/' + id, data)
    }
    static async DisapproveShop(id) {
        let data = {}

        return await apiConnect('/dashboard/disapprove-shop/' + id, data)
    }
    static async ChangePassword(email, code, password, repeat_password) {
        let data = {
            'email': email,
            'code': code,
            'password': password,
            'repeat_password': repeat_password
        }

        return await apiConnect('/forgot_password/change_password', data)
    }
    // static async UploadFile(file) {
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('access_token', localStorage.getItem('access_token'));

    //     let apiHost = 'http://localhost:6020/files/upload'
    //     if (location.href.search('vitisocial.com') > -1) {
    //         apiHost = 'https://api.vitisocial.com/files/upload'
    //         if (location.href.search('test.') > -1) {
    //             apiHost = 'https://apidev.vitisocial.com/files/upload'
    //         }
    //     }
    //     return await $.ajax({
    //         url: apiHost,
    //         method: 'POST',
    //         processData: false,
    //         contentType: false,
    //         data: formData,
    //         success: function(response) {
    //             console.log('success', response)
    //             return response
    //         },
    //         error: function(response) {
    //             console.log('error', response)
    //             return response
    //         }
    //     })
    // }
    static async GetOrders(page, search, order_section) {
        let data = {
            page: page,
            search: search,
            order_section: order_section
        }

        return await apiConnect('/dashboard/get-orders', data)
    }
    static async GetOrder(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/get-order', data)
    }
    static async SendProductOrderMessage(product_order_id, message) {
        let data = {
            product_order_id: product_order_id,
            message: message
        }

        return await apiConnect('/dashboard/send-product-order-message', data)
    }
    static async MarkProductOrder(mark_as, product_order_id) {
        let data = {
            mark_as: mark_as,
            product_order_id: product_order_id
        }

        return await apiConnect('/dashboard/mark-order-product', data)
    }
    static async UserVerificationApplications(search) {
        let data = {
            search
        }

        return await apiConnect('/dashboard/get-user-verification-applications', data)
    }
    static async VerifyUserApplication(application_id) {
        let data = {
            application_id
        }

        return await apiConnect('/dashboard/verify-user', data)
    }
    static async UnverifyUserApplication(application_id) {
        let data = {
            application_id
        }

        return await apiConnect('/dashboard/unverify-user', data)
    }
    static async VerifyUser(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/verify-user', data)
    }
    static async UnverifyUser(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/unverify-user', data)
    }
    static async BanUser(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/ban-user', data)
    }
    static async UnbanUser(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/unban-user', data)
    }
    static async ActivateUser(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/activate-user', data)
    }
    static async CreditUserWallet(user_id, amount) {
        let data = {
            user_id,
            amount
        }

        return await apiConnect('/dashboard/credit-user', data)
    }
    static async DebitUserWallet(user_id, amount) {
        let data = {
            user_id,
            amount
        }

        return await apiConnect('/dashboard/debit-user', data)
    }
    static async CreditUserVitisocialWallet(user_id, amount) {
        let data = {
            user_id,
            amount
        }

        return await apiConnect('/dashboard/vitisocial/credit-user', data)
    }
    static async DebitUserVitisocialWallet(user_id, amount) {
        let data = {
            user_id,
            amount
        }

        return await apiConnect('/dashboard/vitisocial/debit-user', data)
    }
    static async MakeUserAdmin(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/make-user-admin', data)
    }
    static async RemoveUserAdmin(user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/remove-user-admin', data)
    }
    static async GetReports(page, section) {
        let data = {
            page: page,
            section
        }

        return await apiConnect('/dashboard/get-reports', data)
    }
    static async ApproveReport(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/approve-report', data)
    }
    static async RejectReport(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/reject-report', data)
    }
    static async GetCouponCodes(page, search, code_section) {
        let data = { page, search, code_section }

        return await apiConnect('/dashboard/coupon-codes', data)
    }
    static async GetCouponCode(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/coupon-code', data)
    }
    static async CreateCouponCode(type, amount, owner_id) {
        let data = {
            type,
            amount,
            owner_id
        }

        return await apiConnect('/dashboard/create-coupon-code', data)
    }
    static async ActivateCouponCode(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/activate-coupon-code', data)
    }
    static async DeactivateCouponCode(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/deactivate-coupon-code', data)
    }
    static async GetFeeds(page, per_page, section, search) {
        let data = {
            page, 
            per_page, 
            section,
            search
        }

        return await apiConnect('/dashboard/get-feeds', data)
    }
    static async ManageFeedDonation(action, target_amount, end_date_time, id) {
        let data = {
            action, 
            target_amount, 
            end_date_time,
            id
        }

        return await apiConnect('/dashboard/feeds/manage', data)
    }
    static async ManageFeedList(action, id) {
        let data = {
            action,
            id
        }

        return await apiConnect('/dashboard/feeds/manage-list', data)
    }
    static async GetNotys(page, per_page, section, search) {
        let data = {
            page, 
            per_page, 
            section,
            search
        }

        return await apiConnect('/dashboard/noty/get', data)
    }
    static async CreateNoty(type, message, link) {
        let data = {
            type, 
            message, 
            link
        }

        return await apiConnect('/dashboard/noty/create', data)
    }
    static async PauseNoty(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/noty/pause', data)
    }
    static async ActivateNoty(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/noty/activate', data)
    }
    static async RemoveNoty(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/noty/remove', data)
    }
    static async SearchApi(page, per_page, section, search) {
        let data = {
            page, 
            per_page, 
            section,
            search
        }

        return await apiConnect('/dashboard/search', data)
    }
    static async ManualSubscriptionApi(id, plan) {
        let data = {
            id, 
            plan
        }

        return await apiConnect('/dashboard/activate-subscription', data)
    }
    static async CancelSubscriptionApi(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/cancel-subscription', data)
    }
    static async PauseAllAdverts() {
        let data = {}

        return await apiConnect('/dashboard/pause-adverts', data)
    }
    static async GetAllAdmins() {
        let data = {}

        return await apiConnect('/dashboard/user/admins', data)
    }
    static async GetPages(page, section, search) {
        let data = {
            page,
            section,
            search
        }

        return await apiConnect('/dashboard/get-pages', data)
    }
    static async VerifyPage(id) {
        let data = {id}

        return await apiConnect('/dashboard/verify-page', data)
    }
    static async UnverifyPage(id) {
        let data = {id}

        return await apiConnect('/dashboard/unverify-page', data)
    }
    static async GetGroups(page, section, search) {
        let data = {
            page,
            section,
            search
        }

        return await apiConnect('/dashboard/get-groups', data)
    }
    static async VerifyGroup(id) {
        let data = {id}

        return await apiConnect('/dashboard/verify-group', data)
    }
    static async UnverifyGroup(id) {
        let data = {id}

        return await apiConnect('/dashboard/unverify-group', data)
    }
    static async AdminWalletLogs(id, search) {
        let data = {
            id, 
            search
        }

        return await apiConnect('/dashboard/admin-wallet-logs', data)
    }
    static async ChangeUserType(id, type) {
        let data = {
            id,
            type
        }

        return await apiConnect('/dashboard/change-user-type', data)
    }
    static async UploadFile(image) {
        const url = "/upload"
        const data = new FormData()
        data.append("file", image)
        return await apiFileConnect(url, data)
    }
}

async function apiConnect(url, data) {
    let apiHost = 'http://localhost:6020/api/v1/admin'
    apiHost = 'https://apidev.vitisocial.com/api/v1/admin'
    if (location.href.search('localhost') > -1) {
        apiHost = 'http://localhost:6020/api/v1/admin'
    } else if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
        apiHost = 'https://api-three.vitisocial.com/api/v1/admin'
        if (location.href.search('test.') > -1 || location.href.search('admindev.') > -1) {
            apiHost = 'https://apidev.vitisocial.com/api/v1/admin'
        }
    }
    let accessToken = localStorage.getItem('access_token')
    console.log(accessToken, 'accessToken', apiHost)
    if (accessToken) {
        data['access_token'] = accessToken
    }
    console.log(data, 'data')
    return await $.ajax({
        url: apiHost + '' + url,
        method: 'POST',
        data: data,
        success: function(response) {
            console.log(response)
            return response
        },
        error: function(response) {
            console.log(response)
            return response
        }
    })

}

async function apiFileConnect(url, data) {
    try {
        let apiHost = 'http://localhost:6020/files'
        if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
            apiHost = 'https://api.vitisocial.com/files'
            if (location.href.search('test.') > -1 || location.href.search('dev.') > -1) {
                apiHost = 'https://apidev.vitisocial.com/files'
            }
        }
        let accessToken = localStorage.getItem('access_token')
        return await $.ajax({
            url: apiHost + '' + url,
            method: 'POST',
            data: data,
            processData: false,
            contentType: false,
            crossDomain: false,
            success: function(response) {
                console.log('response--------------', response)
                return response
            },
            error: function(response) {
                console.log('---------------response',response)
                return response
            }
        })
    } catch (error) {
        console.log('eeee', error)
    }

}