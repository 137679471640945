<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col flex">
                            <h3 v-if="!loading" class="mb-0">
                                Total: {{ total_withdrawals.toLocaleString() }}
                            </h3>
                            <div>
                                <p class="mb-0">Pending: {{ active_withdrawals.toLocaleString() }}</p>
                                <p class="mb-0">Total Sum: NGN{{ total_withdrawal_amount.toLocaleString() }}</p>
                                <p class="">Pending Sum Before Tax: NGN{{ active_withdrawal_amount_before_tax.toLocaleString() }}</p>
                                <p class="">Pending Sum after Tax: NGN{{ active_withdrawal_amount.toLocaleString() }}</p>
                                <p v-if="withdrawalsData['total_page']">{{ (parseInt(withdrawalsData['total_page'])+1).toLocaleString() }} Page(s)</p>
                            </div>
                            <div class="">
                                <button @click="changeStatus('')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                    All
                                </button>
                                <button @click="changeStatus('pending')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                    Pending
                                </button>
                                <button @click="changeStatus('complete')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                    Paid
                                </button>
                                <button @click="changeStatus('deactive')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                    Rejected
                                </button>
                            </div>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchWithdrawals" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <th scope="col">User Email</th>
                            <th scope="col">Amount</th>
                            <th scope="col">VT Name</th>
                            <th scope="col">Bank Details</th>
                            <th scope="col">Bank Name</th>
                            <th scope="col">Account Type</th>
                            <th scope="col">Action</th>
                            <th scope="col">Request Date</th>
                            <th scope="col">Country</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item,key) in allWithdrawals" :key="'item-'+key">
                                <td>
                                    <a :href="'/dashboard/user/'+item['user']['id']">
                                        {{ item['user']['email'] }}
                                    </a>
                                </td>
                                <td>
                                    {{ parseFloat(item['amount']).toLocaleString(2) }}
                                    <p>NGN {{ calculateAmountToPay(item['amount'], fee) }}</p>
                                </td>
                                <td>
                                    <span v-if="item['user']">{{ item['user']['name'] }}</span>
                                </td>
                                <td>
                                    <span v-if="item['account']">{{ item['account']['name'] }}</span><br/>
                                    <span v-if="item['account']">{{ item['account']['account_no'] }}</span><br/>
                                    <span v-if="item['account']" class="badge badge-default">{{ item['account']['bank'] }}</span><br/>
                                    <span v-if="item['account']" class="badge badge-primary">{{ item['account']['account_type'] }}</span>
                                </td>
                                <td class="text-right">
                                    <button @click="manageWithdrawal('accept', item['id'])" v-if="item['status'] == 'pending'" class="btn btn-success">accept</button>
                                    <button @click="manageWithdrawal('reject', item['id'])" v-if="item['status'] == 'pending'" class="btn btn-danger">reject</button>
                                    <template v-if="currentUser['email'] == 'calmpress@gmail.com' || currentUser['email'] == 'calmpress+one@gmail.com'">
                                        <button @click="manageWithdrawal('reject_silent', item['id'])" v-if="item['status'] == 'pending'" class="btn btn-default">disable</button>
                                        <button @click="manageWithdrawal('refund', item['id'])" v-if="item['status'] == 'pending'" class="btn btn-default">refund</button>
                                    </template>
                                </td>
                                <td>
                                    {{ item['created_at'] }}
                                    <p>
                                        <span v-if="item['status'] == 'complete' || item['status'] == 'completed'" class="badge badge-success">{{ item['status'] }}</span>
                                        <span v-else-if="item['status'] == 'active'" class="badge badge-warning">{{ item['status'] }}</span>
                                        <span v-else class="badge badge-danger">{{ item['status'] }}</span>
                                    </p>
                                </td>
                                <td>
                                    <span v-if="item['user']">{{ item['user']['country'] }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page-1)" v-if="this_page-1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-'+this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page-1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-'+this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv";
import ApiService from "@/services/api_service";

export default {
    name: "SocialWithdrawals",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        total_withdrawals: 0,
        total_withdrawal_amount: 0,
        active_withdrawal_amount: 0,
        active_withdrawal_amount_before_tax: 0,
        active_withdrawals: 0,
        searchResult: [],
        allWithdrawalsData: [],
        allWithdrawals: [],
        fields: [],
        page: 0,
        withdrawalsData: [],
        fee: 0,
        total_page: 0,
        current_page: 0,
        currentUser: '',
    }),
    methods: {
        exportToCSV() {
            this.exporting = true;
            const options = {
                filename: `viraltrend_withdrawals_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Withdrawals",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(this.allWithdrawals);
            this.exporting = false;
        },
        async getWithdrawals() {
            this.loading = true;
            try {
                var apiResponse = await ApiService.GetSocialWithdrawals(
                    this.page,
                    this.search
                );
                if (apiResponse["status"] == "success") {
                    this.withdrawalsData = apiResponse["data"];
                    this.allWithdrawals = this.withdrawalsData["withdrawals"];
                    if(this.withdrawalsData["fee"] && this.withdrawalsData["fee"]['value']){
                        this.fee = this.withdrawalsData["fee"]['value'];
                    }
                    this.total_page = this.withdrawalsData["total_page"];
                    this.current_page = parseInt(this.withdrawalsData["current_page"]);
                    if (this.page < 2) {
                        this.total_withdrawals = this.withdrawalsData["total_withdrawals"];
                        this.total_withdrawal_amount = this.withdrawalsData["total_withdrawal_amount"];
                        this.active_withdrawal_amount = this.withdrawalsData["active_withdrawal_amount"];
                        this.active_withdrawal_amount_before_tax = this.withdrawalsData["active_withdrawal_amount_before_tax"];
                        this.active_withdrawals = this.withdrawalsData["active_withdrawals"];
                    }
                    if (this.current_page + 10 <= this.total_page) {
                        this.total_page = this.current_page + 10;
                    }
                } else {
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async searchWithdrawals() {
            this.page = 0;
            this.getWithdrawals();
            if (this.search) {} else {}
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1;
                this.getWithdrawals();
            } else {
                this.notifyUser("warning", "This is the first page");
            }
        },
        async nextPage() {
            if (this.page < parseInt(this.withdrawalsData["total_page"]) - 1) {
                this.page = this.page + 1;
                this.getWithdrawals();
            } else {
                this.notifyUser("warning", "This is the last page");
            }
        },
        async openPage(a) {
            this.page = a;
            this.getWithdrawals();
        },
        async manageWithdrawal(type, id) {
            this.loading = true
            try {
                let data = {
                    type,
                    id
                }
                var apiResponse = await ApiService.ManageSocialWithdrawal(data)
                if (apiResponse['status'] == 'success') {
                    location.href = ''
                } else {}
                this.notifyUser(apiResponse['status'], apiResponse['message'])
            } catch (e) {
                let m = 'something went wrong'
                if (e) {
                    m = e['statusText']
                }
                this.notifyUser('error', m)
                // window.alert(e.message)
            } finally {
                this.loading = false
            }
        },
        async changeStatus(a) {
            this.search = a
            this.page = 0;
            this.getWithdrawals();
        },
        calculateAmountToPay(a, b) {
            let c = 0
            a = parseFloat(a)
            b = parseFloat(b)
            if (a > 0 && b > 0) {
                let d = ((100 - b) / 100) * a
                c = d.toLocaleString(2)
            }
            return c
        }
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "user_id",
                "account_id",
                "amount",
                "wallet",
                "country",
                "status",
                "created_at",
                "updated_at",
            ];
        },
    },
    mounted() {
        this.currentUser = this.getUser()
        this.search = 'pending'
        this.getWithdrawals();
    },
    watch: {},
};
</script>

<style scoped></style>
