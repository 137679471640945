<template>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header border-0">
                    <p v-if="pagination['last_page']">{{ (parseInt(pagination['last_page'])).toLocaleString() }} Page(s)</p>
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 v-if="!loading && pagination" class="mb-0">
                                {{ pagination["total"].toLocaleString() }} Users
                            </h3>
                            <div class="">
                                <button @click="changeSection('all')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                    All
                                </button>
                                <button @click="changeSection('verified')" class="mb-2 btn btn-sm btn-icon btn-primary mr-3" type="button">
                                    Verified
                                </button>
                                <button @click="changeSection('promoters')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                    Promoters
                                </button>
                                <button @click="changeSection('advertisers')" class="mb-2 btn btn-sm btn-icon btn-primary" type="button">
                                    Advertisers
                                </button>
                            </div>
                        </div>
                        <div class="col px-3 d-flex">
                            <input v-model="search" class="form-control" placeholder="type and click the search button" />
                            <button @click="searchUsers" class="btn btn-sm btn-icon btn-success float-right" type="button">
                                Search
                            </button>
                        </div>
                        <div class="col-old d-none">
                            <button @click="exportToCSV" class="btn btn-sm btn-icon btn-primary float-right" type="button" :data-loading="exporting" :disabled="exporting">
                                Export
                            </button>
                        </div>
                    </div>
                </div>
                <LinearLoader v-if="loading"></LinearLoader>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th v-for="field in tableHeadings" :key="field" scope="col">
                                    {{ field.replace(/_/g, " ") }}
                                </th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,key) in allUsers" :key="key">
                                <td v-for="field in tableHeadings" :key="field">
                                    <template v-if="field == 'id'">
                                        <a :href="'/dashboard/user/'+item[field]">
                                            {{ item[field] }}
                                        </a>
                                    </template>
                                    <template v-else-if="field == 'status'">
                                        <span v-if="item[field] == 'active'" class="badge badge-success">{{ item[field] }}</span>
                                        <span v-else-if="item[field] == 'new'" class="badge badge-warning">{{ item[field] }}</span>
                                        <span v-else class="badge badge-default">{{ item[field] }}</span>
                                    </template>
                                    <template v-else-if="field == 'type'">
                                        <span class="badge badge-info">{{ item[field] }}</span>
                                    </template>
                                    <template v-else>
                                        {{ item[field] }}
                                    </template>
                                </td>
                                <td class="text-right">
                                    <router-link :to="'/dashboard/user/' + item.id" class="btn btn-sm btn-icon btn-primary" type="button" data-toggle="modal" data-target="#user-details">
                                        <span class="btn-inner--icon"><i class="fa fa-eye"></i></span>
                                    </router-link>
                                </td>
                                <td class="text-right">
                                    <template v-if="currentUser['email'] == 'calmpress@gmail.com' || currentUser['email'] == 'calmpress+one@gmail.com'">
                                        <button v-if="item.verified == 'true'" class="btn btn-danger" @click="unverifyUser(item.id)">
                                            unverify
                                        </button>
                                        <button v-else class="btn btn-primary" @click="verifyUser(item.id)">
                                            verify
                                        </button>
                                    </template>
                                </td>
                                <td class="text-right">
                                    <button v-if="item.status == 'deactive'" class="btn btn-danger" @click="unbanUser(item.id)">
                                        unban
                                    </button>
                                    <button v-else class="btn btn-success" @click="banUser(item.id)">
                                        ban
                                    </button>
                                </td>
                                <td class="text-right">
                                    <template v-if="currentUser['email'] == 'calmpress@gmail.com' || currentUser['email'] == 'calmpress+one@gmail.com'">
                                        <button v-if="!item.role" class="btn btn-primary" @click="makeAdmin(item.id)">
                                            make admin
                                        </button>
                                        <button v-else class="btn btn-danger" @click="removeAdmin(item.id)">
                                            remove admin
                                        </button>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mb-3 mx-4">
                    <button @click="previousPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Previous
                    </button>
                    <template v-for="this_page in total_page">
                        <button @click="openPage(this_page - 1)" v-if="this_page - 1 == page" class="btn btn-sm btn-icon btn-primary" type="button" :key="'page-active-' + this_page">
                            {{ this_page }}
                        </button>
                        <button @click="openPage(this_page - 1)" v-else class="btn btn-sm btn-icon btn-default" type="button" :key="'page-' + this_page">
                            {{ this_page }}
                        </button>
                    </template>
                    <button @click="nextPage" class="btn btn-sm btn-icon btn-primary" type="button" :data-loading="loading" :disabled="loading">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ExportToCsv
} from "export-to-csv";
import ApiService from "@/services/api_service";

export default {
    name: "Users",
    components: {},
    data: () => ({
        exporting: false,
        loading: true,
        selectedId: null,
        defFields: [],
        search: "",
        section: "all",
        searchResult: [],
        allUsersData: [],
        allUsers: [],
        total_page: 0,
        current_page: 0,
        fields: [
            "name",
            "username",
            "email",
            "email_verified_at",
            "date_of_birth",
            "profile_image",
            "phone_number",
            "phone_number_verified_at",
            "last_login",
            "credit_score",
            "bvn",
            "country",
            "state",
            "city",
            "lga",
            "address",
            "occupation",
            "marital_status",
            "no_of_children",
            "role",
            "status",
            "created_at",
            "updated_at",
        ],
        page: 0,
        usersData: [],
        currentUser: '',
        pagination: '',
    }),
    methods: {
        exportToCSV() {
            this.exporting = true;
            const options = {
                filename: `viraltrend_users_${Date.now()}`,
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Viraltrend Users",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(this.users);
            this.exporting = false;
        },
        async getAllUsers() {
            this.loading = true;
            try {
                var apiResponse = await ApiService.AllUsers(this.page, this.section, this.search);
                if (apiResponse["status"] == "success") {
                    this.usersData = apiResponse["data"];
                    this.allUsers = this.usersData["users"];
                    this.pagination = apiResponse["data"]["pagination"];
                    this.total_page = this.pagination["last_page"];
                    this.current_page = parseInt(this.pagination["current_page"]);
                    if (this.current_page + 10 <= this.total_page) {
                        this.total_page = this.current_page + 10;
                    }
                } else {
                    this.notifyUser(apiResponse["status"], apiResponse["message"]);
                }
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async searchUsers() {
            this.page = 0;
            if (this.search) {
                this.section = 'search'
            } else {
                this.section = 'all'
            }
            this.getAllUsers();
        },
        async verifyUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.VerifyUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async unverifyUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.UnverifyUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async banUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.BanUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async unbanUser(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.UnbanUser(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async makeAdmin(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.MakeUserAdmin(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async removeAdmin(id) {
            this.loading = true;
            try {
                var apiResponse = await ApiService.RemoveUserAdmin(id);
                this.notifyUser(apiResponse["status"], apiResponse["message"]);
                if (apiResponse["status"] == "success") {
                    setTimeout(() => {
                        location.href = "";
                    }, 1300);
                } else {}
            } catch (e) {
                let m = "something went wrong";
                if (e) {
                    m = e["statusText"];
                }
                this.notifyUser("error", m);
                // window.alert(e.message)
            } finally {
                this.loading = false;
            }
        },
        async previousPage() {
            if (this.page > 0) {
                this.page = this.page - 1;
                this.getAllUsers();
            } else {
                this.notifyUser("warning", "This is the first page");
            }
        },
        async nextPage() {
            if (this.page < this.usersData["total_page"]) {
                this.page = this.page + 1;
                this.getAllUsers();
            } else {
                this.notifyUser("warning", "This is the last page");
            }
        },
        async openPage(a) {
            this.page = a;
            this.getAllUsers();
        },
        changeSection(a){
            this.section = a 
            this.getAllUsers()
        }
    },
    computed: {
        tableHeadings() {
            return [
                "id",
                "name",
                "username",
                "email",
                "type",
                "state",
                "country",
                "gender",
                "email_verified_at",
                "dob",
                "status",
                "created_at",
            ];
        },
    },
    mounted() {
        this.currentUser = this.getUser()
        this.getAllUsers();
    },
    watch: {},
};
</script>

<style scoped></style>
